<template lang="pug">
	div(class="home")
		div(class="row")
			div(class="col s12")
				separator(text="Ticketing Lösungen")
		
		div(class="flex row tile-row")
			div(class="col l6 m12 s12")
				tile(icon="icons/Iconset_DGBRT--01.png" headline="DIY Ticketing" teaser="Die Dagobert Ticketing Plattform inklusive aller Funktionen wie Print@Home und Hardtickets, Saalplänen, Whitelabel Shop und Ticket Personalisierung." link_label="Zum Produkt" link_target="/diy-ticketing" v-bind:link_open_external="false")
			div(class="col l6 m12 s12")
				tile(icon="icons/Iconset_DGBRT--02.png" headline="Custom-Ticketing" teaser="Erweitere die Dagobert Ticketing Plattform maßgeschneidert auf deine Bedürfnisse." link_label="Zum Produkt" link_target="/custom-ticketing" v-bind:link_open_external="false")
		
		div(class="row")
			div(class="col s12")
				separator(text="Dagobert in Action")
		
		div(class="flex row tile-row")
			div(class="col l6 m12 s12")
				tile(icon="icons/Iconset_DGBRT--03.png" headline="Best Practice" teaser="Whitelabel Integrationen von Dagobert Ticket Shops." link_label="Ticketing in Action" link_target="/ticketing-in-action" v-bind:link_open_external="false")
			div(class="col l6 m12 s12")
				tile(icon="icons/Iconset_DGBRT--04.png" headline="Referenzen" teaser="Eine Liste ausgewählter Partner die bereits auf Dagbort vertrauen." link_label="Zu den Referenzen" link_target="/references" v-bind:link_open_external="false")
		
		div(class="row")
			div(class="col s12")
				separator(text="Kontaktiere uns")
		
		div(class="row")
			div(class="col s12")
				form-block(teaser="Hast du Interesse an Dagobert oder Fragen an uns? Lass von dir hören!" source="Startseite" v-bind:form_config="formConfig" submit_label="Abschicken" endpoint="https://website-backend.dagorbert-apps.de/mails/relay" form_submitted_message="Vielen Dank!<br/>Wir melden uns so schnell wie möglich!")

</template>

<style scoped>
@media screen and (max-width: 992px) {
	#page .tile-row {
		margin-bottom: 0px;
	}
}
</style>

<script>
import Separator from '@/components/Separator';
import Tile from '@/components/Tile';
import Form from '@/components/Form';

export default {
	name: 'Home',
	components: {
		'separator': Separator,
		'tile': Tile,
		'form-block': Form
	},
	data: function() {
		return {
			'formConfig': [
				{
					'label': 'Dein Anliegen',
					'key': 'topic',
					'type': 'textarea',
					'required': true
				},
				{
					'label': 'Unternehmen',
					'key': 'company',
					'type': 'text',
					'required': true
				},
				{
					'label': 'Name',
					'key': 'contact_name',
					'type': 'text',
					'required': true
				},
				{
					'label': 'E-Mail Adresse',
					'key': 'contact_email',
					'type': 'email',
					'required': true
				},
				{
					'label': 'Telefonnummer',
					'key': 'phone',
					'type': 'text',
					'required': true
				},
				{
					'label': 'Erwartetes Ticketvolumen pro Jahr',
					'key': 'volume',
					'type': 'number',
					'required': false
				}
			]
		}
	}
}
</script>
