<template lang="pug">
	div(class="tile-container")
		div(class="tile valign-wrapper")
			div(class="container")
				div(class="icon-wrapper")
					img(class="icon" v-bind:src="getImgUrl(icon)" v-bind:alt="headline")
				div(v-bind:class="headlineWrapperClass")
					h4 {{ headline }}
				div(v-if="typeof teaser == 'string'" class="teaser-wrapper")
					p(v-html="teaser")
				div(v-if="link_target && link_label" class="button-wrapper")
					button(v-if="link_internal_scroll" class="btn z-depth-0 tile-button" v-bind:style="styleClass" @click="internalScroll") {{ link_label }}
					a(v-else class="btn z-depth-0 tile-button" v-bind:href="'#'+link_target" v-bind:target="link_open_external ? '_blank' : '_self'" v-bind:style="styleClass") {{ link_label }}
</template>

<style scoped>
.tile-container {
	background-color: #e5e5e5;
	color: #233642;
	width: 100%;
	padding: 50px 0;
}

@media screen and (max-width: 992px) {
	.tile-container {
		margin-bottom: 25px;
	}
}

.icon-wrapper {
	width: 100%;
	text-align: center;
}

.icon {
	width: auto;
	height: auto;
	max-width: 100px;
	max-height: 100px;
}

.headline-wrapper {
	text-align: center;
}

.headline-wrapper h4 {
	font-weight: bold;
}

.headline-wrapper:after {
	content: '';
	width: 60px;
	height: 3px;
	background: #233642;
	display: block;
	margin: 0px auto;
}

.headline-wrapper h4 {
	color: #233642;
	text-transform: uppercase;
}

.headline-wrapper.small-headline h4 {
	font-size: 1.4em;
}

.teaser-wrapper {
	color: #233642;
	text-align: center;
}

.button-wrapper {
	text-align: center;
}

.tile-button {
	background-color: #e1783e;
	border-radius: 0px;
	padding-left: 30px;
	padding-right: 30px;
	font-weight: bold;
	height: auto;
}

@media screen and (max-width: 540px) {
	.headline-wrapper h4 {
		font-size: 1.4em;
	}
}
</style>

<script>
import UrlMixin from '@/mixins/UrlMixin';

export default {
	props: [
		'icon',
		'headline',
		'small_headline',
		'teaser',
		'link_label',
		'link_target',
		'link_open_external',
		'link_internal_scroll',
		'button_color'
	],
	mixins: [ UrlMixin ],
	computed: {
		styleClass() {
			let styleClass = {
				'background-color': typeof this.button_color == 'string' ? this.button_color : '#e1783e'
			}

			return styleClass;
		},
		headlineWrapperClass() {
			return {
				'headline-wrapper': true,
				'small-headline': this.small_headline ? true : false
			}
		}
	},
	methods: {
		internalScroll() {
			if(window['document'].querySelector(this.link_target)) {
				window['document'].querySelector(this.link_target).scrollIntoView({ block: 'start',  behavior: 'smooth' });
			}

		}
	}
}
</script>